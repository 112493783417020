.players-team-records {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  &__player-group {
    height: 300px;
    width: 100%;
    padding: 1em;
    margin-bottom: 1.5em;
    border-radius: 8px;

    .player-details {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      &__name-position {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 0;
        text-transform: capitalize;
      }

      &__name,
      &__position {
        margin-top: 0;
      }

      &__teams {
        background: rgba(255, 255, 255, 1);
        color: $black;
        font-size: 0.77em;
        width: 100%;
        text-align: center;
        border-radius: 3px;

        &--header {
          color: $white;
          background: rgba(0, 0, 0, 0.3);
          font-size: 0.5em;

          th {
            padding: 0.4em 0;
          }
        }
        &--stats {
          background: rgba(0, 0, 0, 0.1);

          td {
            padding: 0.4em;
          }
          td.team-name {
            text-align: left;
          }
        }
      }
      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }

      &__total {
        font-size: 5em;
        margin: 0;
        text-shadow: 2px 2px 3px $black;
        letter-spacing: -7px;
        width: auto;
      }
      &__logo {
        width: 98px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .players-team-records {
    &__player-group {
      width: 300px;

      .player-details {
        &__teams {
          width: 100%;
        }
        &__logo {
          width: 30%;
        }
      }
    }
  }
}
