.standings {
  section {
    padding: 0 1em;

    .loader-height {
      height: 900px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }
  .overall-section {
    padding: 4.3em 0 1em;
    margin: 0;

    @media screen and (min-width: 480px) {
      padding-top: 4.5em;
    }

    .overall-title {
      text-align: center;
      color: $black;
      font-family: $decor;
      font-weight: 100;
      letter-spacing: 1.5px;
    }
  }
}
