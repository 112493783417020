.draft-page {
  padding: 1em;
  // background-image: linear-gradient($black, #808080, #f5f5f5);
  background-image: linear-gradient($currentDark, $white);

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 60px;
      width: 60px;
    }
  }
  h1 {
    text-align: center;
    font-size: 1.5em;
    color: $white;
    font-family: $bold;
  }
  section {
    padding: 3em 0 1em;
  }
}

@media screen and (min-width: 768px) {
  .draft-page {
    padding: 2em;

    h1 {
      font-size: 2.5em;
    }
  }
}
