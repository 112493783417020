.sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;

  &__main {
    background-image: linear-gradient(#f5f5f5, silver);
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    align-items: center;
    border-radius: 1em;
    border: 2px solid #e2e2e2;

    form {
      width: 50%;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.9em;
    }
  }
}
