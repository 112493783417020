.draft-order {
  position: relative;
  padding: 1em 1.5em;
  margin-top: 1em;
  background-image: linear-gradient(#f5f5f5, silver);
  border: 2px solid #ffffff;
  border-radius: 0.5em;

  h3 {
    margin-top: 0;
  }
  form {
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 4em;
  }
  &__list-item {
    display: flex;
    flex-direction: column;
    margin: 0 0.5em;

    label {
      text-align: center;
      text-transform: capitalize;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    input {
      padding: 0.25em 0.75em 0.25em 0.75em;
      font-size: 1.4em;
      font-weight: bold;
      text-align: center;
      border: none;
      border-radius: 4px;
      max-width: 55px;
    }
    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  button {
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    right: 2em;
  }
}

@media screen and (min-width: 768px) {
  .draft-order {
    width: 60%;
    margin-left: 4em;
    margin-top: 0;
  }
}
