.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  overflow: scroll;

  h4 ~ hr {
    width: 150px;
    margin: 0;
    margin-bottom: 1em;
    border-style: solid;
    height: 1px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    border-radius: 16px 16px 0 0;

    h3 {
      text-transform: capitalize;
      color: $white;
      font-size: 1.5em;
    }
  }

  &__body {
    padding: 1.5em 1em 1em 1em;
    background-color: $white;

    h4 {
      margin: 0;
      padding-bottom: 0.5em;
    }

    hr {
      color: #ececec;
      opacity: 0.7;
    }
  }

  &__footer {
    color: $white;
    padding: 1.5em 1em;
    border-radius: 0 0 16px 16px;

    h4 {
      margin: 0;
      padding-bottom: 0.4em;
      font-family: $bold;
    }

    hr {
      color: $white;
      opacity: 0.3;
    }
  }

  &__container {
    width: 95%;
    max-height: 95vh;
    max-width: 500px;
    background: transparent;
    color: black;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

    .spinner-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .current-week-grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .next-week-grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 1.2em;
    }
  }
}
