.team-group {
  width: 80px;
  display: flex;
  align-items: center;
  padding: 0.25em;

  input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  input[type="radio"]:checked + label {
    background-color: $checkedPlayerButtonBG;
    border-color: $checkedPlayerButtonBorder;

    &:hover {
      color: $black;
    }
  }

  label {
    text-transform: capitalize;
    text-align: center;
    display: inline-block;
    background-color: #ddd;
    padding: 0.5em;
    width: 100%;
    font-size: 16px;
    border: 2px solid #444;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      transition: all ease-in-out 0.2s;
      background-color: $currentHighlight;
      border-color: $currentSecondary;
      color: $white;
    }
  }

  input[type="radio"]:focus + label {
    border: 2px dashed $checkedPlayerButtonBorder;
  }
}
