@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url(../../assets/fonts/AvenirNextLTPro-Regular.otf);
}
@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: url(../../assets/fonts/AvenirNextLTPro-Demi.otf);
}
@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url(../../assets/fonts/AvenirNextLTPro-Bold.otf);
}

$bold: "AvenirNextLTPro-Bold";
$demi: "AvenirNextLTPro-Demi";
$regular: "AvenirNextLTPro-Regular";
$decor: "Russo One";
