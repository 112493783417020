.form__group {
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;

  label {
    margin-bottom: 0.3em;
  }

  input {
    font-size: 1em;
    padding: 0.2em;
    border: 2px solid #bbbbbb;
    border-radius: 0.2em;

    ::placeholder {
      font-size: inherit;
    }
  }
}
