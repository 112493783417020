.draft-reset {
  padding: 1em 2.5em;
  background-image: linear-gradient(#f5f5f5, silver);
  border: 2px solid #ffffff;
  border-radius: 0.5em;
  text-align: center;

  h3 {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .draft-reset {
    width: 35%;
    max-width: 480px;
  }
}
